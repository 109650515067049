import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class Understanding extends React.PureComponent {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(2);
    };

    render(){
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="HeartDiseaseMainContainer">
                        <div className="ContentContainer">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionText">Learning that your dog has heart disease can be concerning, but with your care and suitable medication from your vet, a better, longer life for your dog is now achievable.</p>
                                    <p className="SectionText">An important thing to understand is that your dog's heart condition is not uncommon. Heart disease affects around 10% of all dogs.<sup>1</sup></p>
                                    <p className="SectionText">Some heart problems are present from birth; these are termed congenital heart disease. Other types of heart disease develop during the course of the pet's lifetime, often in the middle age, and are called acquired heart disease. Acquired heart disease accounts for an estimated 95% of all heart conditions in dogs.</p>
                                    <p className="SectionTitle">Common causes of acquired heart disease in dogs include:</p>
                                    <ul>
                                        <li className="SectionListElement">&ndash; valve defects (e.g. mitral valve disease)</li>
                                        <li className="SectionListElement">&ndash; cardiac muscle disease (e.g. dilated cardiomyopathy)</li>
                                        <li className="SectionListElement">&ndash; parasitism (e.g. heartworm)</li>
                                    </ul>
                                    <p className="SectionText">These diseases can result in heart failure. Heart failure in dogs is not like a heart attack in people. The term 'heart failure' comes from the heart's inability to pump enough blood to meet the body's needs. This causes the dog to show clinical signs or symptoms as their heart's performance declines over time.</p>
                                    <p className="SectionText">While there is no cure for heart failure, it can usually be managed with medication and care. For more information on preventing heartworm disease, visit <a href="http://nexgardspectra.com.au">nexgardspectra.com.au</a>.</p>
                                    <p className="SectionTitle">How the heart fails in heart failure</p>
                                    <p className="SectionText">The primary function of the heart is to pump blood rich in oxygen around the body and to pump blood needing oxygen to the lungs for reoxygenation. In heart failure, the pumping mechanism is impaired as you can see in these diagrams:</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.heartComparison.childImageSharp.fluid}
                                            className="TypicalImage"
                                        />
                                    </div>
                                </div>
                                <div className="ReferenceContainer">
                                    <p className="ReferenceText">Reference:</p>
                                    <p className="ReferenceText">1. Atkins C., et al. (2006) Guidelines for the diagnosis and treatment of canine chronic valvular heart disease. <em>J Vet Intern Med.</em>;23(6):1142–1150.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

export const query = graphql`{
    heartComparison: file(relativePath: { eq: "heartDisease/understanding.png" }){
        childImageSharp {
            fluid(maxWidth: 950){
                ...GatsbyImageSharpFluid
            }
        }
    }
}`

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Understanding);
